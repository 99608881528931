import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Global, css } from "@emotion/react"

const IndexPage = () => (
  <Layout>
    <Global
      styles={css`
        * {
          margin: 0px;
          padding: 0px;
          scroll-behavior: smooth;
        }
      `}
    />
    <SEO title="おいわけ歯科" />
  </Layout>
)

export default IndexPage
